const localConfig = {
    domain: "exchange.ocenlatam.co",
    api: "https://exchange.ocenlatam.co/api/v1/",
    base: "",
    title: "OCENLATAM",
    captcha: "6Lc-YtsqAAAAANI6-7SvsapMPbWqXVoK5nJhLGcC",
    countryCode: "CO",
    countryCurrency: "COP",
    sentryEnv: "production",
    placesApiKey: "AIzaSyCZ5XUAvjpJooLHwr6bsA1Rm5zPEznKoBU",
    brand: "ocen"
  }
  export default localConfig;
  