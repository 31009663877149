// Constants for backend cards usage

export const IS_CARDS_ENABLED = false;
export const IS_VIRTUAL_CARDS_ENABLED = false;
// If enabling make sure nemo_office address is correct
export const IS_PHYSICAL_CARDS_ENABLED = false;
export const IS_CARD_DELIVERY_ENABLED = false;

export const CARD_ACTIONS = {
    activate: 'active',
    blocked: 'blocked',
    details: undefined,
    fund: undefined
};

export interface CardData {
    brand: string,
    expiry_mm_yyyy: string,
    masked_pan: string, 
    type: string
}
export interface CardInfo {
    balance: number,
    data: CardData,
    status: string,
    tracking_number: string,
    vendor: string,
    fund_currency: string,
    currency: string,
}

export type CardActionType = keyof typeof CARD_ACTIONS;

export enum CardStatuses {
    ACTIVE = "active",
    BLOCKED = "blocked",
    INACTIVE = "inactive",
    ASSIGNED = "assigned",
    UNASSIGNED = "unassigned"
}

export enum CardVendors {
    LIGO = "ligo",
    INSWITCH = "inswitch",
}

