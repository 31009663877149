import { useEffect } from "react";
import usePageLayoutStore from "../../stores/PageLayoutStore"
import useAuthStore from "../../stores/AuthStore"
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdWallet, IoMdSwap, IoIosSettings  } from "react-icons/io";
import { IoMdPeople } from "react-icons/io";
import { useProfile } from "../../hooks/useProfile";
import { BiSupport } from "react-icons/bi";
import { usePairs } from "../../hooks/usePairs";
import { FiArrowUpLeft, FiArrowDownRight } from "react-icons/fi";
import { FaPeopleArrows } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa6";
import { BiLogOut } from "react-icons/bi";
import { IS_CARDS_ENABLED } from "../../utils/cards";
import { IS_PEER_TO_PEER_ENABLED } from "../../utils/transfers";

import "./Sidebar.css"

interface SidebarProps {
    massTransfersEnabled?: boolean
}

const Sidebar = ({ massTransfersEnabled = false }: SidebarProps) => {

    const { t } = useTranslation('common')

    const { data } = useProfile()

    const { data: pairsData } = usePairs()

    const location = useLocation()

    const { isSidebarOpen, setSidebarPosition } = usePageLayoutStore((state) => {
        return {
            isSidebarOpen: state.isSidebarOpen,
            setSidebarPosition: state.setSidebarPosition
        }
    })

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            if(target.id === "sidebar" || target.id === "sidebar-button") {
                return
            }
            setSidebarPosition(false)
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
          document.removeEventListener('click', handleClickOutside);
        }
    }, []);

    const { logOut } = useAuthStore((state) => {
        return {
            logOut: state.logOut,
        }
    }) 

    const isKyc = data?.kyc_status === "green"

    const buttons = isKyc ? [
        {icon: <IoMdWallet className="sidebar-icon"/>, link: "/", text: t('assets')},
        {icon: <IoMdSwap  className="sidebar-icon"/>, link: `/converter/${pairsData?.[0].base.code}/${pairsData?.[0].quote.code}`, text: t('converter')},
        ...(IS_PEER_TO_PEER_ENABLED ? [{icon: <FaPeopleArrows className="sidebar-icon"/>, link: "/peertopeer", text: t('peertopeer')}] : []),
        {icon: <FiArrowDownRight className="sidebar-icon"/>, link: "/deposit/USDT", text: t('deposit')},
        {icon: <FiArrowUpLeft className="sidebar-icon"/>, link: "/withdraw/USDT", text: t('withdrawal')},
        ...(IS_CARDS_ENABLED ? [{icon: <FaCreditCard className="sidebar-icon"/>, link: "/cards", text: t('cards')}] : []),
        ...(massTransfersEnabled ? [{icon: <IoMdPeople className="sidebar-icon"/>, link: "/payrolls", text: t('payrolls')}] : []),
        {icon: <IoIosSettings className="sidebar-icon"/>, link: "/profile", text: t('profile')},
        {icon: <BiSupport className="sidebar-icon"/>, link: "/support", text: t('support')},
    ] : []

    const buttonElems = buttons.map((button) => {
        
        const isActive = button.link === "/" ? location.pathname === "/" : location.pathname.split('/')[1] === (button.link.split('/')[1])

        return <NavLink key={button.link} className={`${!isSidebarOpen ? "circular-radius" : ""} ${isActive ? "sidebar-nav-link active" : "sidebar-nav-link"}`} to={button.link}>
            <div className={`sidebar-icon-text-cont`}>
                {button.icon}
                <span className={`sidebar-button-text ${isSidebarOpen ? "show-button-text" : ""}`}>{button.text}</span>
            </div>
            </NavLink>
    })

    return (
        <div id="sidebar" className={`sidebar-cont ${isSidebarOpen ? "sidebar-open" : "sidebar-closed" }`}>
            {buttonElems}
            <div onClick={logOut} className="sidebar-logout">
                <BiLogOut className="sidebar-icon"/>
                <span className={`sidebar-logout-text ${isSidebarOpen ? "show-button-text" : ""}`}>{t('logout')}</span>
            </div>
        </div>
    )
}

export default Sidebar
